<template>
  <div class="head d-flex justify-content-between align-items-center">
    <div></div>
    <img src="@/assets/images/logo-full.png" class="img-fluid" alt="logo"  width="180"/>
    <div class="d-flex">
      <button v-if="user && token" class="btn btn-primary d-block w-100" @click="$router.push('/')">{{ $t('label.go_index_page') }}</button>
      <template v-else>
        <button class="btn btn-primary d-block w-100" @click="$router.push('/auth/signin')">{{ $t('label.signIn') }}</button>
        <button class="btn btn-primary d-block w-100 ms-2" @click="$router.push('/auth/signup')">{{ $t('label.signUp') }}</button>
      </template>
    </div>
  </div>
  <section>
  <div class="container-xl container-head d-flex">
    <div class="col-lg-8 col-md-12 col-sm-12">
      <div class="container d-flex justify-content-center">
      <img src="@/assets/images/promote/paper-plane.png" alt="icon" class="img-fluid">
      </div>
      <h1 class="stroke-title">創作者!</h1>
      <p class="purple-title">搜尋那些永遠願意為你奮力喝彩的忠實粉絲。</p>
      <p class="purple-text">讓你的創作更有意義，增加其價值與意義</p>
      <section class="input-box ">
        <div class="container row">
          <input v-model="email" placeholder="請輸入電子郵件" value class="col-md-8 col-sm-12">
          <button class="col-md-4 col-sm-12" @click="goRegisterPage(email)">申請免費使用<img src="@/assets/images/promote/gift-icon.png" alt="icon" width="18"></button>
        </div>
          <p>立即申請，可免費使用並獲得優先曝光你的創作</p>
          <img src="@/assets/images/promote/new-arrow.png" alt="" class="img-fluid new-arrow">
      </section>
      
    </div>
    <div class="col-4 right-img">
      <img src="@/assets/images/promote/live-streaming.png" alt="icon" class="img-fluid">
    </div>
  </div>
  </section>

  <section class="background-section pb-ten">
    <div class="container bg-box"  :style="{'background-image': 'url(' + require(`@/assets/images/bg.png`) + ')'}">
      <div class="container d-flex p-20">
        <div class="col-lg-8 col-md-12 col-sm-12 apply-left">
      <h1>在SeeWe讓你的創作更有價值</h1>
      <p>你的支持者可以成為每月會員，免費或付費訂閱你的創作內容，</p>
      <p>包括貼文、圖片、照片和影片。你還可以單獨設定某些貼文為付費內容，一切由你決定。</p>
      <div class=" row">
      <input v-model="email1" placeholder="請輸入電子郵件" value class="col-md-8 col-sm-12">
          <button class="col-md-4 col-sm-12" @click="goRegisterPage(email1)">申請免費使用<img src="@/assets/images/promote/gift-icon.png" alt="icon" width="18"></button>
      </div>
        </div>
        <div class="col-lg-4 apply-right">
          <img src="@/assets/images/promote/promote-bigicon.png" alt="icon" class="img-fluid">
        </div>
      </div> 
    </div>
  </section>

  <section class="container pb-ten">
    <div class=" d-flex row">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <img src="@/assets/images/promote/discount.png" alt="icon" class="img-fluid">
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 cost">
        <h1 class="stroke-title">超低手續費</h1>
      <p class="purple-text">現階段早鳥優惠中，只要您通過創作者申請，您的作品即可在
        SeeWe販售。SeeWe僅收取每筆交易手續費8%~11%，
        並且隨著您的等級提升，手續費會降低。錯過此次機會，下次
        早鳥開放的時間尚未確定！立即申請，不要錯過！</p>
      </div>
    </div>
  </section>

  <section class="container pb-ten">
    <div class="row">
      <div class="talc">
      <h1 class="stroke-title">完美功能，非同凡響</h1>
      <p class="purple-text">我們將協助，讓您的創作被更多人看見，更快被賣出，在這裡你只要專注做出好的創作。
        透過貼文、影片、照片、或是圖片來展現你的創作吧！</p>
      </div>
      <div class="container d-flex justify-content-center align-items-center">
      <img src="@/assets/images/promote/bell-bigicon.png" alt="icon" class="img-fluid">
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="container d-flex small-parts">
              <div class="col-4 d-flex justify-content-center align-items-center">
                <img src="@/assets/images/promote/circle-icon.png" alt="icon" class="img-fluid">
              </div>
              <div class="col-8">
                <p class="purple-title">付費訂閱功能</p>
                <p class="purple-text">讓你真正的支持者
                  訂閱你的創作吧</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="container d-flex small-parts">
              <div class="col-4 d-flex justify-content-center align-items-center">
                <img src="@/assets/images/promote/message-icon.png" alt="icon" class="img-fluid">
              </div>
              <div class="col-8">
                <p class="purple-title">傳訊息</p>
                <p class="purple-text">隨時傳訊息給支持者
                  更親密的接觸</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="container d-flex small-parts">
              <div class="col-4 d-flex justify-content-center align-items-center">
                <img src="@/assets/images/promote/megaphone-icon.png" alt="icon" class="img-fluid">
              </div>
              <div class="col-8">
                <p class="purple-title">加速曝光機制</p>
                <p class="purple-text">別擔心沒人看見你的創作
                  加速曝光找到支持者</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="container d-flex small-parts">
              <div class="col-4 d-flex justify-content-center align-items-center">
                <img src="@/assets/images/promote/handshake-icon.png" alt="icon" class="img-fluid">
              </div>
              <div class="col-8">
                <p class="purple-title">貼文購買功能</p>
                <p class="purple-text">販售你的任何創作
                  你的貼文、影片或圖片</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="container d-flex small-parts">
              <div class="col-4 d-flex justify-content-center align-items-center">
                <img src="@/assets/images/promote/mobile-icon.png" alt="icon" class="img-fluid">
              </div>
              <div class="col-8">
                <p class="purple-title">完整的收費功能</p>
                <p class="purple-text">支援信用卡、ATM轉帳等多樣化的收費方式。</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="container d-flex small-parts">
              <div class="col-4 d-flex justify-content-center align-items-center">
                <img src="@/assets/images/promote/users-icon.png" alt="icon" class="img-fluid">
              </div>
              <div class="col-8">
                <p class="purple-title">粉絲管理系統</p>
                <p class="purple-text">粉絲就是你的客戶
                  精準了解支持者的需求</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container pb-ten">
    <div class=" d-flex row">
      <div class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
        <img src="@/assets/images/promote/content.png" alt="icon" class="img-fluid">
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 column cost">
        <h1 class="stroke-title">眾多精選內容</h1>
      <p class="purple-title">在 SeeWe除了大量的免費公開內容，
        精彩的訂閱內容，滿足你的好奇心。</p>
      </div>
    </div>
  </section>

  <section class="container pb-ten">
    <div class=" d-flex row">
      <div class="col-lg-7 col-md-12 col-sm-12">
        <p class="gift-title">強力曝光 讓創作被看到</p>
        <p class="gift-text">建立自己的粉絲群，不需再擔心辛苦經營的帳號被封禁，
          透過用心經營的粉絲群，你將不再無助地求助。掌握粉絲的手中，
          透過粉絲分眾和等級系統，更深入了解你的支持者，輕鬆地將訊息
          和創作傳達給真正懂你的人。</p>
          <p class="gift-txt"><span class="highlight">01.</span>創作者更多行銷功能</p>
          <p class="gift-txt"><span class="highlight">02.</span>發送訊息與粉絲更直接的接觸</p>
          <p class="gift-txt"><span class="highlight">03.</span>專屬粉絲互動功能</p>
          <p class="gift-txt"><span class="highlight">04.</span>粉絲管理系統與等級制度</p>
          <p class="gift-txt"><span class="highlight">05.</span>獨一無二的連結，管理你所有內容</p>
      </div>
      <div class="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
        <img src="@/assets/images/promote/gift-bigicon.png" alt="icon" class="img-fluid">
      </div>
    </div>
  </section>

  <section class="background-section">
    <div class="container bg-box"  :style="{'background-image': 'url(' + require(`@/assets/images/bg.png`) + ')'}">
        <div class="container">
          <div class="get-box">
          <p>GET STARTED INSTANTLY!</p>
      <h1>現在申請，無條件免費使用</h1>
          </div>
      <div class="container row get">
      <input v-model="email2" placeholder="請輸入電子郵件" value class="col-md-8 col-sm-12">
          <button class="col-md-4 col-sm-12" @click="goRegisterPage(email2)">申請免費使用<img src="@/assets/images/promote/gift-icon.png" alt="icon" width="18"></button>
      </div>
        </div> 
    </div>
  </section>

  <footer class="footer">
    <div class="">
      <p>Copyright © 2023 SeeWe</p>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      email: '',
      email1: '',
      email2: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    })
  },
  methods: {
    goRegisterPage(email) {
      if (email === '') {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.$t('label.emailPlaceholder')
        })
        return;
      }
      this.$router.push('/auth/signup');
    }
  }
}
</script>

<style >
body{
  background-color: #F9F7F4 !important;
  font-family: Microsoft JhengHei;
}
.swiper-area {
  overflow-x: hidden;
}

body{
  background-color: #F9F7F4;
}

.head{
  border-bottom: 0.8px solid #CCCCCC;
  padding: 5px;
}

.container-head{
  padding-top: 70px;
}

.stroke-title{
  font-size: 50px;
    font-weight: bold;
    color: transparent;
    -webkit-text-stroke: 0.8px #F98A5A;
}

.purple-title{
  font-size: 30px;
  font-weight: bold;
  color: #34003D;
  margin-bottom: 0;
}

.purple-text{
  font-size: 20px;
  font-weight: bold;
  color: #34003D;
  
}

.input-box input{
  background-color: #fff;
    border: 2px solid #34003D;
    border-radius: 40px;
    padding: 10px;
}

.input-box button{
  border-radius: 40px;
    padding: 10px 20px 10px 20px;
    background-color: #F98A5A;
    border: none;
    color: #fff;
    font-size: 14px;
}

.get input{
  background-color: #fff;
    border: 2px solid #34003D;
    border-radius: 40px;
    padding: 10px;
}

.get button{
  border-radius: 40px;
    padding: 10px 20px 10px 20px;
    background-color: #F98A5A;
    border: none;
    color: #fff;
    font-size: 14px;
}

.get{
  padding: 10px 70px 40px 70px;
}

.get img{
  margin-bottom: 5px;
    margin-left: 5px;
}

.input-box img{
  margin-bottom: 5px;
    margin-left: 5px;
}

.input-box p{
  color: #F98A5A;
  font-size: 15px;
  font-weight: bold;
  padding-top: 10px;
}

@media only screen and (max-width: 992px) {
  .right-img{
    display: none;
  }

  .input-box button{
    margin-top: 10px;
  }

  .get button{
    margin-top: 10px;
  }

  .apply-right{
    display: none;
  }
  
  .apply-left button{
    margin-top: 10px;
  }

  .apply-left{
    padding: 20px !important;
  }

  .cost{
    padding-left: 0px !important;
  }

  .get{
    padding: 10px 0px 40px 15px;
  }
}

.new-arrow{
  float: right;
    width: 15%;
}

.background-section {
  /* 设置背景图片 */
  position: relative;
  background-color: transparent; /* 清除默认背景颜色 */
}

.bg-box {
  /* 容器的样式 */
  width: 100%; /* 或者你想要的宽度 */
  height: 100%; /* 或者你想要的高度 */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}

.apply-left{
  padding: 60px;
}

.apply-left h1{
  color: #fff;
  font-weight: 500;
  font-size: 40px;
}

.apply-left p{
  color: #fff;
  font-weight: 300;
  font-size: 15px;

}

.apply-left input{
  background-color: #fff;
    border: 2px solid #34003D;
    border-radius: 40px;
    padding: 10px;
}

.apply-left button{
  border-radius: 40px;
    padding: 10px 20px 10px 20px;
    background-color: #F98A5A;
    border: none;
    color: #fff;
    font-size: 14px;
}

.apply-left img {
  margin-bottom: 5px;
  margin-left: 5px;
}

.apply-right img{
  padding-top: 20px;
  width: 100%;
}

.cost{
  padding-top: 40px;
  padding-left: 40px;
}

.pb-ten{
  padding-bottom: 10%;
}

.talc{
  text-align-last: center;
  padding-bottom: 20px;
}

.small-parts{
background-color: #fff;
border-radius:10px;
box-shadow:2px 10px 20px #333;
margin-top: 30px;
padding: 10px 10px 0px 10px;
display: flex;
  justify-content: center;    
  align-items: center; 
}

.column{
  display: flex;
    justify-content: center;
    flex-direction: column;
}

.gift-title{
  font-size: 45px;
    font-weight: bold;
    color: #34003D;
}

.gift-txt{
  font-size: 23px;
    font-weight: bold;
    color: #34003D;
    margin-bottom: 5px;
}

.gift-text{
  color: #4D4D4D;
  font-size: 16px;
  font-weight: bold;
  padding-right: 10%;
}

.highlight{
  color: #F98A5A;
  padding-right: 10px;
}

.get-box p{
  display: flex;
  justify-content: center;    
  align-items: center; 
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding-top: 40px;
  margin-bottom: 0;
}

.get-box h1{
  display: flex;
  justify-content: center;    
  align-items: center; 
  color: #fff;
  font-weight: bold;
  font-size: 50px;
}

.footer{
  background-color: #B3B3B3;
padding-bottom: 10px;
margin-top: 70px;
}

.footer p{
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;    
  align-items: center;  
  padding-top: 10px;
  margin-bottom: 0;
}
</style>
